import { BluetoothLe } from '@capacitor-community/bluetooth-le';
import React, { useState, useEffect } from 'react';
// #define SERVICE_UUID        "0000ff00-0000-1000-8000-00805f9b34fb"
// #define CHARACTERISTIC_UUID "0000ff01-0000-1000-8000-00805f9b34fb"
const deviceName = "NalicoDevice_452211";
const serviceUUID = "0000ff00-0000-1000-8000-00805f9b34fb";
const characteristicUUID = "0000ff01-0000-1000-8000-00805f9b34fb";
var device;
function App() {
  const [connected, setConnected] = useState(false);
  const [characteristic, setCharacteristic] = useState(null);

  useEffect(() => {
    connectToDevice();
  }, []);

  async function connectToDevice() {
    // Request Bluetooth device
    const devices = await BluetoothLe.getDevices();
    alert(devices)
    device = await BluetoothLe.requestDevice({
      name: deviceName,
      services: [serviceUUID]
    });

    // Connect to device
    await BluetoothLe.connect({
      deviceId: device.deviceId
    });

    // Discover service and characteristic
    const service = await BluetoothLe.discover({
      deviceId: device.deviceId,
      serviceUUID: serviceUUID
    });
    const char = await BluetoothLe.discover({
      deviceId: device.deviceId,
      serviceUUID: serviceUUID,
      characteristicUUID: characteristicUUID
    });

    // Save characteristic and set connected state
    setCharacteristic(char);
    setConnected(true);
  }

  async function writeData() {
    // Convert data to ArrayBuffer
    const data = new TextEncoder().encode("Hello World!").buffer;

    // Write data to characteristic
    await BluetoothLe.write({
      deviceId: device.deviceId,
      serviceUUID: serviceUUID,
      characteristicUUID: characteristicUUID,
      value: data
    });
  }

  return (
    <div>
      {connected ? (
        <button onClick={writeData}>Send Data</button>
      ) : (
        <p>Connecting to device...</p>
      )}
    </div>
  );
}

export default App;
